/* .swap {
  border: 1px solid red;
} */

.swap .main {
  /* border: 1px solid blue; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

@media (max-width: 1350px) {
  .swap .main .main-right {
    margin-left: 24px;
    min-width: 852px;
    border-radius: 12px;
    overflow: hidden;
    background: #fff;
    /* border: 1px solid red; */

    box-shadow: 0px 2px 16px 0px rgba(255, 184, 231, 0.5);
  }
}

.swap .main .main-right {
  margin-left: 24px;
  min-width: 1052px;
  border-radius: 12px;
  overflow: hidden;
  background: #fff;
  /* border: 1px solid red; */

  box-shadow: 0px 2px 16px 0px rgba(255, 184, 231, 0.5);
}

.swap .main .main-right #color-bar {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.01);
}

.ant-table-thead > tr > th {
  color: #5a3f2d !important;
}

.ant-pagination-item-active {
  border-color: #c41010 !important;
}

.ant-pagination-item-active a {
  background-color: #c41010 !important;
  color: #fff !important;
}
a:hover {
  color: #c41010 !important;
  background-color: #fff !important;
}
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: #c41010 !important;
  color: #c41010 !important;
}

.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #c41010 !important;
  border-color: #c41010 !important;
}

a {
  color: #c41010;
}
.columnsTitle > span {
  margin: 0 10px;
  color: gray;
}

.columnsTitle > span.active {
  color: #5a3f2d;
}
